import { FC } from "react";
import { styled } from "@mui/material/styles";
import Landing from "./components/Landing";
import About from "./components/About";
// import Mission from "./components/Mission";
import Utilities from "./components/Utilities";
import Roadmap from "./components/Roadmap";
import Legendaries from "./components/Legendaries";
import Team from "./components/Team";
import Footer from "./components/Footer";
import Faqs from "./components/Faqs";
import Video from "./components/Video";
import GotoTop from "./components/GotoTop";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100vh",
}));

const Index: FC = () => {
  return (
    <MainDiv>
      <Landing />
      <Video />
      <About />
      {/* <Mission /> */}
      <Utilities />
      <Roadmap />
      <Legendaries />
      <Team />
      <Faqs />
      <Footer />
      <GotoTop />
    </MainDiv>
  );
};

export default Index;

import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  IconButton,
  TextField,
  Button,
  useMediaQuery,
  Link,
  InputAdornment,
  Snackbar,
} from "@mui/material";
import { useState } from "react";
import { useTheme } from "@emotion/react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers, providers } from "ethers";
import { useCallback, useEffect, useReducer } from "react";
import WalletLink from "walletlink";
import Web3Modal from "web3modal";
import { ellipseAddress, getChainData } from "../../lib/utilities";

import CryptoBulbLogo from "../../assets/images/Crypto-Bulb-logo.png";
import CryptoBulbGif from "../../assets/images/CB_GIF.gif";
import { useSearchParams } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const abi = require("../../lib/abi.json");

const INFURA_ID = "578bf4e7e0904a5aa9908fd9d5e6d181";

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: INFURA_ID, // required
    },
  },
  "custom-walletlink": {
    display: {
      logo: "https://play-lh.googleusercontent.com/PjoJoG27miSglVBXoXrxBSLveV6e3EeBPpNY55aiUUBM9Q1RCETKCOqdOkX2ZydqVf0",
      name: "Coinbase",
      description: "Connect to Coinbase Wallet (not Coinbase App)",
    },
    options: {
      appName: "Coinbase", // Your app name
      networkUrl: `https://mainnet.infura.io/v3/${INFURA_ID}`,
      chainId: 1,
    },
    package: WalletLink,
    connector: async (_: any, options: any) => {
      const { appName, networkUrl, chainId } = options;
      const walletLink = new WalletLink({
        appName,
      });
      const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
      await provider.enable();
      return provider;
    },
  },
};

let web3Modal: any;
if (typeof window !== "undefined") {
  web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true,
    providerOptions, // required
  });
}

type StateType = {
  provider?: any;
  web3Provider?: any;
  address?: string;
  chainId?: number;
};

type ActionType =
  | {
      type: "SET_WEB3_PROVIDER";
      provider?: StateType["provider"];
      web3Provider?: StateType["web3Provider"];
      address?: StateType["address"];
      chainId?: StateType["chainId"];
    }
  | {
      type: "SET_ADDRESS";
      address?: StateType["address"];
    }
  | {
      type: "SET_CHAIN_ID";
      chainId?: StateType["chainId"];
    }
  | {
      type: "RESET_WEB3_PROVIDER";
    };

const initialState: StateType = {
  provider: null,
  web3Provider: null,
  address: "",
  chainId: 0,
};

function reducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case "SET_WEB3_PROVIDER":
      return {
        ...state,
        provider: action.provider,
        web3Provider: action.web3Provider,
        address: action.address,
        chainId: action.chainId,
      };
    case "SET_ADDRESS":
      return {
        ...state,
        address: action.address,
      };
    case "SET_CHAIN_ID":
      return {
        ...state,
        chainId: action.chainId,
      };
    case "RESET_WEB3_PROVIDER":
      return initialState;
    default:
      throw new Error();
  }
}

const Main = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  width: "60%",

  [theme.breakpoints.down("md")]: {
    width: "85%",
  },
}));

const Logo = styled("img")(({ theme }) => ({
  marginTop: "30px",
  cursor: "pointer",
  width: "400px",

  [theme.breakpoints.down("md")]: {
    width: "250px",
  },
}));

const Text = styled("div")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

const TextTitle = styled("div")(({ theme }) => ({
  fontSize: "34px",
  fontWeight: "600",
  color: "#fff",
  [theme.breakpoints.down("md")]: {
    fontSize: "20px",
  },
}));

const ColorText = styled("div")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600",
  color: "#fff",
  background: "#99bc44",
  marginBottom: "5px",
  width: "40%",
  marginLeft: "auto",
  marginRight: "auto",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    fontSize: "16px",
  },
}));

const MainCard = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  width: "50%",
  padding: "15px",
  borderRadius: "10px",

  background: "#99bc44",

  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const MainButton = styled("div")(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  width: "60%",

  [theme.breakpoints.down("md")]: {
    width: "90%",
  },
}));

const Mint = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const [ref, setRef] = useState(searchParams.get("ref") || "");

  const [open, setOpen] = useState(false);

  console.log("ref", ref);

  //@ts-ignore
  const isMobile = useMediaQuery(theme?.breakpoints?.down("sm"));
  let [count, setCount] = useState(1);

  const [loading, setLoading] = useState(false);

  let [totalSupply, setTotalSupply] = useState(0);

  function incrementCount() {
    count = count + 1;
    setCount(count);
  }
  function decrementCount() {
    if (count === 1) return;
    count = count - 1;
    setCount(count);
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    provider,
    address,
    chainId,
    // web3Provider,
  } = state;

  const connect = useCallback(async function () {
    // This is the initial `provider` that is returned when
    // using web3Modal to connect. Can be MetaMask or WalletConnect.
    const provider = await web3Modal.connect();

    // We plug the initial `provider` into ethers.js and get back
    // a Web3Provider. This will add on methods from ethers.js and
    // event listeners such as `.on()` will be different.
    const web3Provider = new providers.Web3Provider(provider);

    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();

    const network = await web3Provider.getNetwork();

    dispatch({
      type: "SET_WEB3_PROVIDER",
      provider,
      web3Provider,
      address,
      chainId: network.chainId,
    });
  }, []);

  const disconnect = useCallback(
    async function () {
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === "function") {
        await provider.disconnect();
      }
      dispatch({
        type: "RESET_WEB3_PROVIDER",
      });
    },
    [provider]
  );

  // Auto connect to the cached provider
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connect();
    }
  }, [connect]);

  // A `provider` should come with EIP-1193 events. We'll listen for those events
  // here so that when a user switches accounts or networks, we can update the
  // local React state with that new information.
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        // eslint-disable-next-line no-console
        console.log("accountsChanged", accounts);
        dispatch({
          type: "SET_ADDRESS",
          address: accounts[0],
        });
      };

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        window.location.reload();
      };

      const handleDisconnect = (error: { code: number; message: string }) => {
        // eslint-disable-next-line no-console
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider, disconnect]);

  const chainData = getChainData(chainId);

  useEffect(() => {
    (async () => {
      // Second parameter is chainId, 1 for Ethereum mainnet
      const provider = new ethers.providers.JsonRpcProvider(
        "https://eth-mainnet.g.alchemy.com/v2/7a9sF5iHvIDXgaGujxmttWeS91JWV0PI",
        1
      );
      const signer = new ethers.Wallet(
        "8da4ef21b864d2cc526dbdb2a120bd2874c36c9d0a1fb7f8c63d7f7a8b41de8f",
        provider
      );

      const contractInstance = new ethers.Contract(
        "0xedd6445b20bb5859283a8899102d30fab3ffbc93", //contract addrtess
        abi,
        signer
      );

      const totalSupply = await contractInstance.totalSupply();
      // console.log("contractInstance", totalSupply);
      setTotalSupply(totalSupply.toString());
    })();
  }, [loading]);

  const mintNFT = async () => {
    try {
      if (!provider) return;
      if (!address) return;

      setLoading(true);
      const web3Provider = new providers.Web3Provider(provider);

      const signer = web3Provider.getSigner();

      const contractInstance = new ethers.Contract(
        "0xedd6445b20bb5859283a8899102d30fab3ffbc93", //contract addrtess
        abi,
        signer
      );

      const cost = await contractInstance.cost();
      const amount = cost.mul(count);
      console.log(amount);

      const transaction = await contractInstance.mint(count, {
        value: amount,
      });

      console.log("transaction", transaction);
      alert("Transaction submitted....");

      const receipt = await transaction.wait();
      setLoading(false);
      setCount(1);

      await serverHit(transaction.hash, address, ref)

      alert("Transaction Successfull!");

      console.log("receipt", receipt);
    } catch (error) {
      setLoading(false);
    }
  };

  const serverHit = async (hash: string, owner: string, referrer: string ) => {
    const res = await axios({
      method: "post",
      url: "https://cryptobulb-server.vercel.app/",
      // url: "http://localhost:8080/",
      headers: {},
      data: {
        title: "cryptobulb",
        description: "cryptobulb description",
        published: true,
        hash,
        owner,
        referrer,
      },
    });
    console.log("res111", res)
  };

  return (
    <div>
      <Main>
        <Logo src={CryptoBulbLogo} />
        {/* <button onClick={serverHit}>hit</button> */}
        <br />
        <Text>
          Hey Buddy! Are you passionate about digital art, gaming, blockchain
          technology, knowledge and enlightenment? Then, you are in the right
          place. Our mission and vision are to bring you all that.
        </Text>

        <br />
        <br />

        <TextTitle>Choose your NFT investment journey</TextTitle>

        <ColorText>1 x CryptoBulb NFT 0.055 ETH (100$*)</ColorText>
        <ColorText>5 x CryptoBulb NFT 0.25 ETH (475$*)</ColorText>
        <ColorText>10 x CryptoBulb NFT 0.50 ETH (950$*)</ColorText>
        <ColorText>20 x CryptoBulb NFT 1.0 ETH (1900$*)</ColorText>

        <br />
        <br />

        <MainCard>
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: "24px", color: "#fff", fontWeight: "800" }}>
              CryptoBulb
            </div>
            <div
              style={{
                marginLeft: "auto",
                fontSize: "24px",
                color: "#fff",
                fontWeight: "800",
              }}
            >
              {totalSupply}/10,000
            </div>
          </div>
          <img
            alt="CryptoBulbGif"
            src={CryptoBulbGif}
            width="100%"
            style={{ borderRadius: "10px" }}
          />
        </MainCard>

        <br />

        <MainButton>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton onClick={incrementCount}>
              <AddCircleOutlineIcon
                sx={{
                  color: "#fff",
                  height: isMobile ? "35px" : "50px",
                  width: isMobile ? "35px" : "50px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
            </IconButton>

            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{
                background: "#99bc44",
                borderRadius: "50px",
                width: isMobile ? "100px" : "150px",
              }}
              disabled
              value={count}
              sx={{
                input: {
                  textAlign: "center",
                  fontSize: isMobile ? "20" : "25px",
                  fontWeight: "bolder",
                  color: "#000",
                },
                "& fieldset": { border: "none" },
              }}
            />

            <IconButton onClick={decrementCount}>
              <RemoveCircleOutlineIcon
                sx={{
                  color: "#fff",
                  height: isMobile ? "35px" : "50px",
                  width: isMobile ? "35px" : "50px",
                  marginTop: "auto",
                  marginBottom: "auto",
                }}
              />
            </IconButton>
          </div>
          <br />
          <Text>ETH {count * 0.055} + gas fee</Text>
          {address && (
            <>
              <Text>Address: {ellipseAddress(address)}</Text>
              <Text>Network: {chainData?.name}</Text>
            </>
          )}
          <Text>{``}</Text>

          <br />

          {provider ? (
            <>
              {loading ? (
                <p>loading.....</p>
              ) : (
                <Button
                  variant="outlined"
                  style={{
                    background: "#99bc44",
                    borderRadius: "30px",
                    fontWeight: "bolder",
                    fontSize: isMobile ? "16px" : "20px",
                  }}
                  size="large"
                  onClick={mintNFT}
                >
                  Buy Now
                </Button>
              )}
            </>
          ) : (
            <Button
              variant="outlined"
              style={{
                background: "#99bc44",
                borderRadius: "30px",
                fontWeight: "bolder",
                fontSize: isMobile ? "16px" : "20px",
              }}
              size="large"
              onClick={connect}
            >
              Connect
            </Button>
          )}

          <br />
          <br />

          {address && (
            <>
              <Text>
                <span style={{ fontWeight: "bolder" }}>
                  Get 10% commission per NFT when friends buy using your
                  referral link!
                </span>
              </Text>

              <TextField
                value={`https://cryptobulb.io/mint?ref=${address}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <CopyToClipboard
                        text={`https://cryptobulb.io/mint?ref=${address}`}
                        onCopy={() => setOpen(true)}
                      >
                        <IconButton
                          // onClick={() => {
                          //   handleCopyClick(`https://cryptobulb.io/mint?ref=${address}`)
                          // }}
                          style={{ color: "#fff" }}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </CopyToClipboard>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  marginTop: "5px",
                  input: {
                    color: "#fff",
                  },
                  // "& fieldset": { border: "none" },
                  borderColor: "#fff",
                }}
              />
            </>
          )}
        </MainButton>

        <Snackbar
          message="Copied to clibboard"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          autoHideDuration={2000}
          onClose={() => setOpen(false)}
          open={open}
        />

        <br />
        <br />
        <br />
        <br />
      </Main>
    </div>
  );
};

export default Mint;

import { FC } from "react";
import { styled } from "@mui/material/styles";
import Opensea from "../../../../assets/images/opensea.png";
import Twitter from "../../../../assets/images/twitter.png";
import Discord from "../../../../assets/images/discord.png";
import Instagram from "../../../../assets/images/instagram.png";
import BannerImage from "../../../../assets/images/main-banner.png";
// import CryptoBulbLogo from "../../../../assets/images/Crypto-Bulb-logo.png";
import MintSoonLogo from "../../../../assets/images/MINT-SOON.png";
import MintNowLogo from "../../../../assets/images/mint-now.png";
import Link from "@mui/material/Link";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import { useNavigate } from "react-router-dom";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up("lg")]: {
    height: "100vh",
  },
}));

const Header = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  display: "flex",
  // justifyContent: "space-between",
  justifyContent: "flex-end",
  marginTop: "40px",
  padding: "0px 50px",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
  },
  [theme.breakpoints.up("lg")]: {
    marginTop: "0px",
  },
}));

const SocialContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "auto",

  [theme.breakpoints.down("sm")]: {
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const NavContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const NavItem = styled("h1")(({ theme }) => ({
  margin: "10px 15px",
  cursor: "pointer",
  zIndex: "100",
  fontSize: "25px",
  color: theme.palette.background.paper,
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    height: "30px",
  },

  ":hover": {
    color: theme.palette.text.primary,
  },
}));

const Socials = styled("img")(({ theme }) => ({
  // width: "100%",
  height: "40px",
  margin: "10px",
  cursor: "pointer",
  zIndex: "100",

  [theme.breakpoints.down("sm")]: {
    height: "30px",
  },
}));

const Banner = styled("img")(({ theme }) => ({
  width: "56%",
  // marginTop: "5px",

  [theme.breakpoints.down("md")]: {
    width: "95%",
    marginTop: "5px",
  },
}));

// const CryptoBulb = styled("img")(({ theme }) => ({
//   width: "30%",
//   marginTop: "-8%",
//   [theme.breakpoints.down("md")]: {
//     width: "50%",
//     marginTop: "0%",
//   },
// }));

const MintNow = styled("img")(({ theme }) => ({
  width: "12%",
  cursor: "pointer",
  // marginTop: "-100px",

  // marginTop: "21px",
  // marginBottom: "80px",

  ":focus , ": {
    width: "13%",
  },

  [theme.breakpoints.down("md")]: {
    width: "30%",
    marginTop: "10px",
    marginBottom: "30px",

    ":focus , :hover": {
      width: "41%",
    },
  },
}));

const Index: FC = () => {
  const navigate = useNavigate();

  const gaEventTracker = useAnalyticsEventTracker("Landing page");

  return (
    <MainDiv>
      <div
        style={{
          // border: "2px red solid",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <Header>
          <div style={{ display: "flex", width: "100%" }}>
            <SocialContainer>
              <NavContainer>
                <Link href="#about" underline="none">
                  <NavItem>About</NavItem>
                </Link>
                {/* <Link href="#mission" underline="none">
                  <NavItem>Mission</NavItem>
                </Link> */}
                <Link href="#utilities" underline="none">
                  <NavItem>Utilities</NavItem>
                </Link>
                <Link href="#roadmap" underline="none">
                  <NavItem>Roadmap</NavItem>
                </Link>
                <Link href="#team" underline="none">
                  <NavItem>Team</NavItem>
                </Link>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
              </NavContainer>
              <Link
                href="#"
                underline="none"
                onClick={() => gaEventTracker("opensea")}
              >
                <Socials src={Opensea} />
              </Link>
              <Link
                href="https://twitter.com/CryptoBulbNFT"
                underline="none"
                target="_blank"
                onClick={() => gaEventTracker("twitter")}
              >
                <Socials src={Twitter} />
              </Link>
              <Link
                href="https://discord.gg/p3S7xNABMQ"
                underline="none"
                target="_blank"
                onClick={() => gaEventTracker("discord")}
              >
                <Socials src={Discord} />
              </Link>
              <Link
                href="https://www.instagram.com/cryptobulbnft"
                underline="none"
                target="_blank"
                onClick={() => gaEventTracker("instagram")}
              >
                <Socials src={Instagram} />
              </Link>
            </SocialContainer>
          </div>
        </Header>
        <Banner src={BannerImage} /> <br />
        {/* <CryptoBulb src={CryptoBulbLogo} /> <br /> */}
        <MintNow
          src={MintNowLogo}
          onClick={() => {
            // window.open("https://discord.gg/p3S7xNABMQ", "_blank");
            navigate("/mint");
            gaEventTracker("mint soon button");
          }}
        />
      </div>
    </MainDiv>
  );
};

export default Index;

import { FC } from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import Roadmap1 from "../../../../assets/images/roadmap/Roadmap1.png";
import Roadmap2 from "../../../../assets/images/roadmap/Roadmap2.png";
import Roadmap3 from "../../../../assets/images/roadmap/Roadmap3.png";
import Roadmap4 from "../../../../assets/images/roadmap/Roadmap4.png";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.default,
  overflow: "hidden",
  padding: "80px 50px",
  borderTop: "solid 5px " + theme.palette.background.paper,
  paddingBottom: "0px",

  [theme.breakpoints.down("md")]: {
    padding: "30px 10px",
    paddingBottom: "0px",
  },
}));

const Heading = styled("div")(({ theme }) => ({
  fontSize: "40px",
  fontWeight: "600",
  color: "#fff",
}));

const Dot = styled("div")(({ theme }) => ({
  height: "15px",
  width: "15px",
  borderRadius: "100%",
  backgroundColor: theme.palette.background.paper,
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "25px",
}));

const LeftContainer = styled("div")(({ theme }) => ({
  width: "calc(50% - 2px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
}));

const CenterLine = styled("div")(({ theme }) => ({
  width: "4px",
  backgroundColor: theme.palette.background.paper,
}));

const RightContainer = styled("div")(({ theme }) => ({
  width: "calc(50% - 2px)",
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
}));

const Image = styled("img")(({ theme }) => ({
  height: "180px",
  margin: "50px 30px",

  [theme.breakpoints.down("sm")]: {
    height: "150px",
    margin: "40px 20px",
  },
  transition: "all .2s ease-in-out",

  ":hover": {
    transform: "scale(1.15)",
  },
}));

const Text = styled("div")(({ theme }) => ({
  fontSize: "25px",
  width: "fit-content",
  color: theme.palette.background.paper,
  padding: "0px 13px",

  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
    padding: "0px 13px",
  },
}));

const SubText = styled("div")(({ theme }) => ({
  fontSize: "20px",
  width: "fit-content",
  color: "#fff",
  padding: "0px 0px",
  textAlign: "left",

  [theme.breakpoints.down("sm")]: {
    fontSize: "15px",
    padding: "0px 0px",
  },
}));

const Index: FC = () => {
  const theme = useTheme();

  const HorizontalRightBar = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            flex: 1,
            height: "4px",
            //@ts-ignore
            backgroundColor: theme.palette.background.paper,
          }}
        />

        <div
          style={{
            height: "15px",
            width: "15px",
            borderRadius: "100%",
            //@ts-ignore
            backgroundColor: theme.palette.background.paper,
          }}
        />
      </div>
    );
  };

  const HorizontalLeftBar = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <div
          style={{
            height: "15px",
            width: "15px",
            borderRadius: "100%",
            //@ts-ignore
            backgroundColor: theme.palette.background.paper,
          }}
        />
        <div
          style={{
            width: "100%",
            height: "4px",
            //@ts-ignore
            backgroundColor: theme.palette.background.paper,
          }}
        />
      </div>
    );
  };

  return (
    <MainDiv id="roadmap">
      <Heading>ROADMAP</Heading>
      <Dot />
      <div style={{ display: "flex" }}>
        <LeftContainer>
          <Image src={Roadmap1} />
          {
            //phase2
          }
          <Text>PHASE 2</Text>
          <SubText style={{ textAlign: "right" }}>
            <HorizontalLeftBar />
            <div style={{ padding: "0px 13px" }}>
              P2E Game Development.
              <br />
              Aggressive Marketing Campaign.
              <br />
              Trading Competition.
              <br />
              NFT Lottery.
              <br />
              Charity.
            </div>
          </SubText>
          <Image src={Roadmap3} />
          {
            //phase4
          }
          <Text>PHASE 4</Text>
          <SubText style={{ textAlign: "right" }}>
            <HorizontalLeftBar />
            <div style={{ padding: "0px 13px" }}>
              NFTverse Development Starts.
              <br />
              Merchandise Launch.
              <br />
              NFT Lottery.
              <br />
              Charity.
              <br />
              Trading Competition.
              <br />A Huge Surprise for NFT Holders.
            </div>
          </SubText>
          <br />
          <br />
          <br />
        </LeftContainer>
        <CenterLine />
        <RightContainer>
          {
            //phase1
          }
          <Text>PHASE 1</Text>
          <SubText>
            <HorizontalRightBar />
            <div style={{ padding: "0px 13px" }}>
              Listing on Open Sea.
              <br />
              Marketing Push.
              <br />
              Influncer Onboard.
              <br />
              Social Media Campaign.
              <br />
              Rarity Tools.
              <br />
              NFT Lottery ($10,000 Prize/lottery distributed in 10 Winners).
              <br />
              Charity.
            </div>
          </SubText>
          <Image src={Roadmap2} />
          {
            //phase3
          }
          <Text>PHASE 3</Text>
          <SubText>
            <HorizontalRightBar />
            <div style={{ padding: "0px 13px" }}>
              Partnerships and Collaborations.
              <br />
              Investments in Crypto Startups.
              <br />
              NFT Lottery.
              <br />
              Charity.
            </div>
          </SubText>
          <Image src={Roadmap4} />
        </RightContainer>
      </div>
    </MainDiv>
  );
};

export default Index;

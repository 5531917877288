import { useState, FC } from "react";
import { useTheme } from "@emotion/react";
import { Collapse, useMediaQuery } from "@mui/material";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";

const Tile: FC<{ heading: string; content: string }> = ({
  heading,
  content,
}) => {
  const theme = useTheme();
  //@ts-ignore
  const isTablet = useMediaQuery(theme?.breakpoints?.down("smd"));
  //@ts-ignore
  const isMobile = useMediaQuery(theme?.breakpoints?.down("sm"));
  const [expandStatus, setExpandStatus] = useState<boolean>(false);

  const gaEventTracker = useAnalyticsEventTracker("FAQs");

  return (
    <div
      style={{
        width: isMobile ? "95%" : isTablet ? "90%" : "750px",
        margin: "15px auto",
        fontWeight: "600",
      }}
    >
      <div
        style={{
          padding: "7px 21px",
          //@ts-ignore
          backgroundColor: theme?.palette?.background?.paper,
          //@ts-ignore
          color: theme?.palette?.background?.default,
          fontSize: isMobile ? "20px" : "27px",
          fontWeight: "400",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>{heading}</div>
        <div
          style={{
            marginLeft: "auto",
            cursor: "pointer",
            fontSize: isMobile ? "30px" : "37px",
            color: "#000",
            lineHeight: "0",
          }}
          onClick={() => {
            setExpandStatus(!expandStatus);
            gaEventTracker(heading);
          }}
        >
          {expandStatus ? "x" : "+"}
        </div>
      </div>
      <Collapse in={expandStatus}>
        <div
          style={{
            padding: "15px 21px",
            backgroundColor: "#fff",
            fontSize: "17px",
            textAlign: "left",
            color: "#000",
          }}
        >
          {content}
        </div>
      </Collapse>
    </div>
  );
};

export default Tile;

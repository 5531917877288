/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import Team1 from "../../../../assets/images/team/team1.png";
import Team2 from "../../../../assets/images/team/team2.png";
import Team3 from "../../../../assets/images/team/team3.png";
import Team4 from "../../../../assets/images/team/team4.png";
import Team5 from "../../../../assets/images/team/team5.png";
import Team6 from "../../../../assets/images/team/team6.png";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.default,
  overflow: "hidden",
  padding: "80px 50px",
  borderTop: "solid 5px " + theme.palette.background.paper,

  [theme.breakpoints.down("md")]: {
    padding: "30px 10px",
  },
}));

const Heading = styled("div")(({ theme }) => ({
  fontSize: "40px",
  fontWeight: "600",
  color: "#fff",
}));

const Index: FC = () => {
  const theme = useTheme();

  const getTeamTile = (img: any, heading: string, role: any) => {
    return (
      <div
        style={{
          margin: "9px",
          display: "flex",
          width: "280px",
        }}
      >
        <img src={img} style={{ width: "105px", lineHeight: "0" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "flex-end",
            //@ts-ignore
            color: theme?.palette?.text?.primary,
            fontSize: "25px",
            fontWeight: "600",
            marginLeft: "9px",
            marginBottom: "7px",
          }}
        >
          {heading}
          <span
            style={{
              textAlign: "left",
              fontSize: "18px",
              color: "#fff",
              fontWeight: "500",
            }}
          >
            {role}
          </span>
        </div>
      </div>
    );
  };

  return (
    <MainDiv id="team">
      <Heading>TEAM</Heading>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          // maxWidth: "850px",
          marginTop: "30px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "center",
        }}
      >
        {getTeamTile(Team1, "ZKLong", <>Founder & CTO</>)}
        {getTeamTile(Team2, "DeAlvi", <>Founder & Lead Artist </>)}
        {getTeamTile(Team3, "DA BIG JAY", <>Creative Director</>)}
        {getTeamTile(Team5, "Voids", <>Full Stack Developer </>)}
        {getTeamTile(Team4, "Flux", <>Marketing Manager</>)}
        {getTeamTile(Team6, "Leone", <>Community Manager</>)}
      </div>
    </MainDiv>
  );
};

export default Index;

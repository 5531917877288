import React from "react";
import { styled } from "@mui/material/styles";
import VideoJS from "./VideoJS"; // point to where the functional component is stored

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.default,
}));

const Index = () => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      // {
      //   src: "https://firebasestorage.googleapis.com/v0/b/cryptobulb.appspot.com/o/CryptoBulb_Sneak_Peak.mp4?alt=media&token=febdb232-d73f-4929-aa74-3c2cad0b805f",
      //   type: "video/mp4",
      // },
      {
        src: "https://firebasestorage.googleapis.com/v0/b/cryptobulb.appspot.com/o/CryptoBulb%20NFT%20promo.mp4?alt=media&token=98e93b8a-e52c-4f7b-9603-ee3710277872",
        type: "video/mp4",
      },
    ],
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    <MainDiv>
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div>
    </MainDiv>
  );
};

export default Index;

/* eslint-disable jsx-a11y/alt-text */
import { FC, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import TopIcon from "../../../../assets/images/top.png";

const TopButton = styled("div")(({ theme }) => ({
  width: "60px",
  height: "60px",
  position: "fixed",
  right: "20px",
  bottom: "20px",
  backgroundColor: theme.palette.background.paper,
  padding: "7px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  boxShadow: "1px 1px 10px rgba(0,0,0,0.3)",
  borderRadius: "7px",

  [theme.breakpoints.down("sm")]: {
    width: "40px",
    height: "40px",
    right: "10px",
    bottom: "10px",
  },
}));

const Index: FC = () => {
  const [status, setStatus] = useState(false);

  const gotoTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleScroll = () => {
    if (
      document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100
    ) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };

  useEffect(() => {
    const onScroll = () => handleScroll();
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      {status ? (
        <TopButton
          onClick={() => {
            gotoTop();
          }}
        >
          <img src={TopIcon} style={{ height: "100%" }} />
        </TopButton>
      ) : null}
    </>
  );
};

export default Index;

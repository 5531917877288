import beelzebulb from "../../../../assets/images/legendaries/beelzebulb.png";
import boblin from "../../../../assets/images/legendaries/boblin.png";
import bubbulb from "../../../../assets/images/legendaries/bubbulb.png";
import bulbzlightyear from "../../../../assets/images/legendaries/bulbzlightyear.png";
import bulgenie from "../../../../assets/images/legendaries/bulgenie.png";
import bulien from "../../../../assets/images/legendaries/bulien.png";
import frankobulb from "../../../../assets/images/legendaries/frankobulb.png";
import superbulb from "../../../../assets/images/legendaries/superbulb.png";

import {
  i60_src,
  i61_src,
  i62_src,
  i63_src,
  i64_src,
  i65_src,
  i66_src,
  i67_src,
  i68_src,
  i69_src,
  i70_src,
  i71_src,
  i72_src,
  i73_src,
  i74_src,
  i75_src,
  i76_src,
  i77_src,
  i78_src,
  i79_src,
  i80_src,
  i81_src,
  i82_src,
  i83_src,
  i84_src,
  i85_src,
  i86_src,
  i87_src,
  i88_src,
  i89_src,
  i90_src,
  i91_src,
  i92_src,
  i93_src,
  i94_src,
  i95_src,
} from "../../../../assets/images/index";

export const Legends = [
  { src: beelzebulb, name: "Beelzebulb" },
  { src: boblin, name: "Boblin" },
  { src: bubbulb, name: "Bubbulb" },
  { src: bulbzlightyear, name: "Bulbz Lightyear" },
  { src: bulgenie, name: "Bulgenie" },
  { src: bulien, name: "Bulien" },
  { src: frankobulb, name: "Frankobulb" },
  { src: superbulb, name: "Superbulb" },
  //2x
  { src: beelzebulb, name: "Beelzebulb" },
  { src: boblin, name: "Boblin" },
  { src: bubbulb, name: "Bubbulb" },
  { src: bulbzlightyear, name: "Bulbz Lightyear" },
  { src: bulgenie, name: "Bulgenie" },
  { src: bulien, name: "Bulien" },
  { src: frankobulb, name: "Frankobulb" },
  { src: superbulb, name: "Superbulb" },
];

export const Collections = [
  { src: i60_src, name: "" },
  { src: i61_src, name: "" },
  { src: i62_src, name: "" },
  { src: i63_src, name: "" },
  { src: i64_src, name: "" },
  { src: i65_src, name: "" },
  { src: i66_src, name: "" },
  { src: i67_src, name: "" },
  { src: i68_src, name: "" },
  { src: i69_src, name: "" },
  { src: i70_src, name: "" },
  { src: i71_src, name: "" },
  { src: i72_src, name: "" },
  { src: i73_src, name: "" },
  { src: i74_src, name: "" },
  { src: i75_src, name: "" },
  { src: i76_src, name: "" },
  { src: i77_src, name: "" },
  { src: i78_src, name: "" },
  { src: i79_src, name: "" },
  { src: i80_src, name: "" },
  { src: i81_src, name: "" },
  { src: i82_src, name: "" },
  { src: i83_src, name: "" },
  { src: i84_src, name: "" },
  { src: i85_src, name: "" },
  { src: i86_src, name: "" },
  { src: i87_src, name: "" },
  { src: i88_src, name: "" },
  { src: i89_src, name: "" },
  { src: i90_src, name: "" },
  { src: i91_src, name: "" },
  { src: i92_src, name: "" },
  { src: i93_src, name: "" },
  { src: i94_src, name: "" },
  { src: i95_src, name: "" },
];

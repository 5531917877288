/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import { styled } from "@mui/material/styles";
import { useTheme } from "@emotion/react";
import styledComponent from "styled-components";
import AutoScroller from "../../../../components/auto-scroller";
import { Legends, Collections } from "./images";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.default,
  overflow: "hidden",
  padding: "80px 0px",
  borderTop: "solid 5px " + theme.palette.background.paper,

  [theme.breakpoints.down("md")]: {
    padding: "50px 0px",
  },
}));

const Heading = styled("div")(({ theme }) => ({
  fontSize: "40px",
  fontWeight: "600",
  color: "#fff",
}));

const Container = styledComponent.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Index: FC = () => {
  const theme = useTheme();

  const getLegendTile = (img: any, heading: string) => {
    return (
      <div
        style={{
          //@ts-ignore
          border: "solid 3px" + theme?.palette?.background?.paper,
          width: "150px",
          margin: "9px",
          display: "flex",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <img src={img} style={{ width: "100%", lineHeight: "0" }} />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            fontSize: "20px",
            fontWeight: "600",
            //@ts-ignore
            borderTop: heading
              ? "solid 3px" +
                //@ts-ignore
                theme?.palette?.background?.paper
              : "none",
          }}
        >
          {heading}
        </div>
      </div>
    );
  };

  return (
    <MainDiv>
      <Heading>LEGENDARIES</Heading>
      <br />
      <br />
      <Container>
        <AutoScroller margin="0 30px" speed="60s" pauseOnHover={false}>
          {Legends &&
            Legends.map((legend, index) => {
              return (
                <span key={index}>
                  {" "}
                  {getLegendTile(legend.src, legend.name)}
                </span>
              );
            })}
        </AutoScroller>
      </Container>
      <br />
      <br />
      <Heading>10,000 COLLECTIONS</Heading>
      <br />
      <Container>
        <AutoScroller margin="0 30px" speed="70s" pauseOnHover={false}>
          {Collections &&
            Collections.map((col, index) => {
              return (
                <span key={index}> {getLegendTile(col.src, col.name)}</span>
              );
            })}
        </AutoScroller>
      </Container>
    </MainDiv>
  );
};

export default Index;

import { FC } from "react";
import { styled } from "@mui/material/styles";
// import Background from "../../../../assets/images/about-background.png";
// import BackgroundMobile from "../../../../assets/images/about-background-mobile.png";
import BackgroundGif from "../../../../assets/images/website-gif.gif";
// import { useTheme } from "@emotion/react";
// import { useMediaQuery } from "@mui/material";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.paper,
  overflow: "hidden",
  lineHeight: "0",
}));

const Banner = styled("img")(({ theme }) => ({
  width: "100vw",
  lineHeight: "0",
}));

const Index: FC = () => {
  // const theme = useTheme();
  //@ts-ignore
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <MainDiv id="about">
      {/* <Banner src={isMobile ? BackgroundMobile : Background} /> */}
      <Banner src={BackgroundGif} />
    </MainDiv>
  );
};

export default Index;

/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import { styled } from "@mui/material/styles";
import Utility1 from "../../../../assets/images/utility/utility1.png";
import Utility2 from "../../../../assets/images/utility/utility2.png";
import Utility3 from "../../../../assets/images/utility/utility3.png";
import Utility4 from "../../../../assets/images/utility/utility4.png";
import Utility5 from "../../../../assets/images/utility/utility5.png";
import Utility6 from "../../../../assets/images/utility/utility6.png";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.paper,
  overflow: "hidden",
  padding: "30px 50px",
  paddingBottom: "90px",

  [theme.breakpoints.down("md")]: {
    padding: "30px 10px",
  },
}));

const Heading = styled("div")(({ theme }) => ({
  fontSize: "40px",
  fontWeight: "600",
  color: theme.palette.background.default,
}));

const Text = styled("div")(({ theme }) => ({
  fontSize: "20px",
  fontWeight: "600",
  color: "#000",
  marginTop: "20px",
}));

const Index: FC = () => {
  const theme = useTheme();
  //@ts-ignore
  const isTablet = useMediaQuery(theme?.breakpoints?.down("md"));
  //@ts-ignore
  const isMobile = useMediaQuery(theme?.breakpoints?.down("sm"));

  const getUtilityTile = (img: any, heading: string, text: any) => {
    return (
      <div style={{ display: "flex", fontWeight: "600", margin: "5px" }}>
        <img src={img} style={{ height: isMobile ? "140px" : "180px" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "top",
            padding: "15px 20px",
          }}
        >
          <div
            style={{
              //@ts-ignore
              color: theme?.palette?.background?.default,
              fontSize: isMobile ? "15px" : "20px",
            }}
          >
            {heading}
          </div>
          <div
            style={{
              color: "#000",
              textAlign: "left",
              fontSize: isMobile ? "15px" : "20px",
              maxWidth: "230px",
            }}
          >
            {text}
          </div>
        </div>
      </div>
    );
  };

  return (
    <MainDiv id="utilities">
      <Heading>NFT UTILITIES</Heading>
      <Text>
        NFTs are cool, but we are not treating our NFTs as a static cool-looking
        images, rather we are planning a whole load of utilities for the NFTs
        that you own.
      </Text>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginTop: "30px",
          padding: isMobile ? "0px" : "0px 50px",
          maxWidth: "1000px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: isTablet ? "center" : "space-between",
        }}
      >
        {getUtilityTile(
          Utility1,
          "ETH Reward:",
          <>
            Three trading competitions in a month where the top 3 users with
            high trading volume will be rewarded in ETH.
          </>
        )}

        {getUtilityTile(
          Utility5,
          "NFT lottery:",
          <>
            If you hold 5 NFTs, you will be eligible to participate in a
            twice-monthly lottery and have a chance to win a huge reward.
          </>
        )}

        {getUtilityTile(
          Utility4,
          "AiRdRop:",
          <>
            {" "}
            Airdrops from affiliate projects, increasing NFT holdings for being
            part of the community.
          </>
        )}

        {getUtilityTile(
          Utility2,
          "Staking:",
          <>
            Stake your NFT to be rewarded in $Light token, airdrops and get
            whitelisted in our future project which will be announced in the
            future.
          </>
        )}

        {getUtilityTile(
          Utility3,
          "Holder’s only Discord Channel:",
          <>
            Verify holder status for exclusive Discord access with airdrop tips,
            blockchain knowledge, and more.
          </>
        )}
        {getUtilityTile(
          Utility6,
          "More Coming Soon...",
          <>
            Exciting NFT community surprises coming soon! Stay tuned for more.
          </>
        )}
      </div>
    </MainDiv>
  );
};

export default Index;

import { useRef } from "react";
import {
  Container,
  ContentHolder,
  ContentWrapper,
  DuplicateContentHolder,
} from "./styles";

interface IProps {
  children: JSX.Element[];
  margin?: string;
  speed: string;
  pauseOnHover?: boolean;
}

const AutoScroller = ({ children, margin, speed, pauseOnHover }: IProps) => {
  const primaryScrollerRef = useRef<
    React.RefObject<HTMLDivElement> | null | undefined | any
  >(null);
  const secondaryScrollerRef = useRef<
    React.RefObject<HTMLDivElement> | null | undefined | any
  >(null);

  const mouseEnterHandler = () => {
    if (pauseOnHover) {
      if (!!primaryScrollerRef.current) {
        primaryScrollerRef.current.style.animationPlayState = "paused";
        //@ts-ignore
        primaryScrollerRef.current.style.webkitAnimationPlayState = "paused";
      }
      if (!!secondaryScrollerRef.current) {
        //@ts-ignore
        secondaryScrollerRef.current.style.animationPlayState = "paused";
        //@ts-ignore
        secondaryScrollerRef.current.style.webkitAnimationPlayState = "paused";
      }
    }
  };

  const mouseLeaveHandler = () => {
    if (pauseOnHover) {
      if (!!primaryScrollerRef.current) {
        //@ts-ignore
        primaryScrollerRef.current.style.animationPlayState = "running";
        //@ts-ignore
        primaryScrollerRef.current.style.webkitAnimationPlayState = "running";
      }
      if (!!secondaryScrollerRef.current) {
        //@ts-ignore
        secondaryScrollerRef.current.style.animationPlayState = "running";
        //@ts-ignore
        secondaryScrollerRef.current.style.webkitAnimationPlayState = "running";
      }
    }
  };

  return (
    <Container margin={margin}>
      <ContentHolder ref={primaryScrollerRef} speed={speed}>
        {children.map((c) => (
          <ContentWrapper
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            key={c.key}
          >
            {c}
          </ContentWrapper>
        ))}
      </ContentHolder>
      <DuplicateContentHolder ref={secondaryScrollerRef} speed={speed}>
        {children.map((c) => (
          <ContentWrapper
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            key={c.key}
          >
            {c}
          </ContentWrapper>
        ))}
      </DuplicateContentHolder>
    </Container>
  );
};

AutoScroller.defaultProps = {
  speed: "15s",
};

export default AutoScroller;

import i60 from './collections/60.png'
import i61 from './collections/61.png'
import i62 from './collections/62.png'
import i63 from './collections/63.png'
import i64 from './collections/64.png'
import i65 from './collections/65.png'
import i66 from './collections/66.png'
import i67 from './collections/67.png'
import i68 from './collections/68.png'
import i69 from './collections/69.png'
import i70 from './collections/70.png'
import i71 from './collections/71.png'
import i72 from './collections/72.png'
import i73 from './collections/73.png'
import i74 from './collections/74.png'
import i75 from './collections/75.png'
import i76 from './collections/76.png'
import i77 from './collections/77.png'
import i78 from './collections/78.png'
import i79 from './collections/79.png'
import i80 from './collections/80.png'
import i81 from './collections/81.png'
import i82 from './collections/82.png'
import i83 from './collections/83.png'
import i84 from './collections/84.png'
import i85 from './collections/85.png'
import i86 from './collections/86.png'
import i87 from './collections/87.png'
import i88 from './collections/88.png'
import i89 from './collections/89.png'
import i90 from './collections/90.png'
import i91 from './collections/91.png'
import i92 from './collections/92.png'
import i93 from './collections/93.png'
import i94 from './collections/94.png'
import i95 from './collections/95.png'

export const i60_src = i60
export const i61_src = i61
export const i62_src = i62
export const i63_src = i63
export const i64_src = i64
export const i65_src = i65
export const i66_src = i66
export const i67_src = i67
export const i68_src = i68
export const i69_src = i69
export const i70_src = i70
export const i71_src = i71
export const i72_src = i72
export const i73_src = i73
export const i74_src = i74
export const i75_src = i75
export const i76_src = i76
export const i77_src = i77
export const i78_src = i78
export const i79_src = i79
export const i80_src = i80
export const i81_src = i81
export const i82_src = i82
export const i83_src = i83
export const i84_src = i84
export const i85_src = i85
export const i86_src = i86
export const i87_src = i87
export const i88_src = i88
export const i89_src = i89
export const i90_src = i90
export const i91_src = i91
export const i92_src = i92
export const i93_src = i93
export const i94_src = i94
export const i95_src = i95


/* eslint-disable jsx-a11y/alt-text */
import { FC } from "react";
import { styled } from "@mui/material/styles";
import Tile from "./Tile";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.default,
  overflow: "hidden",
  padding: "80px 50px",
  borderTop: "solid 5px " + theme.palette.background.paper,

  [theme.breakpoints.down("md")]: {
    padding: "30px 10px",
  },
}));

const Heading = styled("div")(({ theme }) => ({
  fontSize: "40px",
  fontWeight: "600",
  color: "#fff",
}));

const Index: FC = () => {
  return (
    <MainDiv>
      <Heading>FAQs</Heading>
      <br />
      <Tile
        heading="WHAT MAKES CRYPTOBULB NFT UNIQUE?"
        content="Cryptobulb is unique in so many ways.  Collecting any two legendaries characters will give you 1 ETH reward.  There is a P2E Game being developed.  There are frequent surprise airdrops.  NFT lotteries are held periodically.  We are building a Metaverse for our community.  Finally, we have devised a charity mission to provide Green Electricity in poor areas and bring light back into their lives."
      />
      <Tile heading="MAX SUPPLY?" content="10,000 with 500+ Traits" />
      <Tile heading="BLOCKCHAIN?" content="Ethereum." />
      <Tile heading="WHEN IS THE LAUNCH?" content="TBA." />
      <Tile
        heading="HOW MANY CRYPTOBULB NFTS CAN I MINT?"
        content="TBA."
      />
      <Tile
        heading="WHAT IS THE MINT PRICE OF CRYPTOBULB NFT?"
        content="TBA."
      />
      <br />
    </MainDiv>
  );
};

export default Index;

import { FC } from "react";
import { styled } from "@mui/material/styles";
import Opensea from "../../../../assets/images/opensea.png";
import Twitter from "../../../../assets/images/twitter.png";
import Discord from "../../../../assets/images/discord.png";
import Instagram from "../../../../assets/images/instagram.png";
import Link from "@mui/material/Link";
import useAnalyticsEventTracker from "../../../../hooks/useAnalyticsEventTracker";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const MainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  height: "fit-content",
  backgroundColor: theme.palette.background.default,
  overflow: "hidden",
  padding: "30px",
  borderTop: "solid 5px " + theme.palette.background.paper,
}));

const SocialContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "15px",
}));

const Socials = styled("img")(({ theme }) => ({
  height: "40px",
  margin: "10px 10px",
  cursor: "pointer",
  zIndex: "100",

  [theme.breakpoints.down("sm")]: {
    height: "30px",
  },
}));

const Heading = styled("div")(({ theme }) => ({
  fontSize: "25px",
  fontWeight: "500",
  color: "#fff",
  marginTop: "10px",
  marginBottom: "10px",
}));

const Index: FC = () => {
  const gaEventTracker = useAnalyticsEventTracker("Footer");
  const theme = useTheme();
  //@ts-ignore
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const SocialIconStyle = {
    height: !isMobile ? "40px" : "30px",
    width: !isMobile ? "40px" : "30px",
    color: "#fff",
    margin: "10px 10px",
    cursor: "pointer",
    zIndex: "100",
  };

  return (
    <MainDiv>
      <SocialContainer>
        <Link
          href="mailto:info@cryptobulb.io"
          underline="none"
          // target="_blank"
          onClick={() => gaEventTracker("email")}
        >
          <MailOutlineIcon sx={SocialIconStyle} />
        </Link>
        <Link
          href="#"
          underline="none"
          onClick={() => gaEventTracker("opensea")}
        >
          <Socials src={Opensea} />
        </Link>
        <Link
          href="https://twitter.com/CryptoBulbNFT"
          underline="none"
          target="_blank"
          onClick={() => gaEventTracker("twitter")}
        >
          <Socials src={Twitter} />
        </Link>
        <Link
          href="https://discord.gg/p3S7xNABMQ"
          underline="none"
          target="_blank"
          onClick={() => gaEventTracker("discord")}
        >
          <Socials src={Discord} />
        </Link>
        <Link
          href="https://www.instagram.com/cryptobulbnft"
          underline="none"
          target="_blank"
          onClick={() => gaEventTracker("instagram")}
        >
          <Socials src={Instagram} />
        </Link>
        <Link
          href="https://www.linkedin.com/company/cryptobulb/"
          underline="none"
          target="_blank"
          onClick={() => gaEventTracker("linkedin")}
        >
          <LinkedInIcon sx={SocialIconStyle} />
        </Link>
      </SocialContainer>
      <Heading>&copy; 2022 CRYPTOBULB</Heading>
    </MainDiv>
  );
};

export default Index;
